
.card {
    border-radius: 6px;
    background-color: #FFFFFF;
    margin-bottom: 20px;
    box-shadow: 0 25px 20px -21px rgba(0,0,0,0.57);
    margin-top:30px;
}
.card-background {
    position: relative;
    text-shadow: 0 1px 3px rgba(0, 0, 0, 0.5);
}
.card-background .image {
    border-radius: 6px;
}
.card .image {
    width: 100%;
    overflow: hidden;
    height: 260px;
    border-radius: 6px 6px 0 0;
    position: relative;
    -webkit-transform-style: preserve-3d;
    -moz-transform-style: preserve-3d;
    transform-style: preserve-3d;
}
.card .image img {
    width: 100%; 
}

.card-background .filter {
    opacity: 0.20;
    filter: alpha(opacity=20.00000000000001);
    border-radius: 6px;
}
.card .filter {
    position: absolute;
    z-index: 2;
    background-color: rgba(0, 0, 0, 0.68);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    text-align: center;
}
.card-background:hover .filter {
    opacity: 0.65;
    filter: alpha(opacity=65);
}
.card-background .content, .card-background .footer {
    position: absolute;
    z-index: 3;
    top: 0;
    left: 0;
    width: 100%;
}
.card .content {
    padding: 15px 15px 10px 15px;
}
.card-background .price {
    margin: 0;
    color: #ffffff;
    font-weight:normal;
}
.card-background .title {
    margin-top: 30px;
    font-weight: 400;
}
.card-background .title, .card-background .stats, 
.card-background .category, 
.card-background .description, 
.card-background small, .card-background a {
    color: #ffffff;
}
.card .title {
    margin: 0 0 10px 0;
    color: #333333;
    font-weight: 300;
}
.card-background .footer {
    bottom: 0;
    top: auto;
    padding: 10px 15px;
    width: 100%;
    line-height: 40px;
    color: #ffffff;
}
.card .footer div {
    display: inline-block;
}
.card .author {
    font-size: 12px;
    text-transform: uppercase;
}
.zoom{
    transition: 1.5s ease;
     -moz-transition: 1.5s ease; 
     -webkit-transition: 1.5s ease; 
     -o-transition: 1.5s ease; 
}
.zoom:hover{
    transform : scale(1.1);
    -moz-transform : scale(1.1); 
    -webkit-transform : scale(1.1); 
    -o-transform : scale(1.1); 
    -ms-transform : scale(1.1); 
}